import { React, Link, styled } from 'x'
import { useState } from 'hooks'
import { colors, textStyles } from 'styles'
import { loadStripe } from '@stripe/stripe-js';

import Cookies from 'js-cookie'

import PayPalIcon from '../assets/svg/donate/paypal_icon.svg'
import PetjeafIcon from '../assets/svg/donate/petjeaf_icon.svg'

const stripePromise = loadStripe('pk_live_51IlaitL13kRZrJ2SDLkL4F5TRPwcIxmsEV2o7faQq0qZfJTCXyZn6relClb9qY3lmEvodtNKGGmfQu2Al4q92ACU00CKrq3cUB');

const Donate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${ colors.borderColor };
`

const Heading = styled.h3`
  ${ textStyles.h3Style }
  margin-bottom: 20px;
`

const DonateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
`

const BtnStyling = props =>`
  height: 70px;
  width: auto;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 1;
  background-color: transparent;
  border: 0;
  text-decoration: none;
  color: ${ colors.textColor };
`

const DonateBtn = styled(Link)`
  ${ BtnStyling }
`

const DonateTxt = styled.span`
  ${ BtnStyling }
  width: 100%;
  height: auto;
  flex-basis: 1;
  padding: 0 0 10px;
  margin: auto 0 20px;
  border-bottom: 2px solid ${ colors.borderColor };
  > span {
    height: auto;
    line-height: 26px;
    font-size: 18px;
  }
`

const Label = styled.span`
  display: inline-block;
  font-weight: bold;
  text-align: center;
  width: 100%;
`

const Icon = styled.span`
  height: 35px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 400;
  svg {
    height: 32px;
    width: auto;
    path {
      transition: .2s fill ease;
    }
  }

  ${({ hover }) => hover && `
    &:hover {
      svg {
        path {
          fill: ${ colors.primaryBlue };
        }
      }
    }
  `}
`

const Text = styled.span`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
`

const PaymentWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid ${ colors.borderColor };
  span {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 100%;
  }
`

const InputWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin: 15px 0 0;
`

const PaymentInput = styled.input`
  width: 70%;  
  padding: 5px 10px;
  border: 1px solid ${ colors.borderColor };
  border-radius: 3px;
  line-height: 20px;
  color: #777;
`

const PaymentSubmit = styled.button`
  margin: 0 0 0 10px;  
  border: 0;
  border-radius: 2px;
  background-color: ${colors.secundaryBlue};
  color: #FFFFFF;
  font-weight: bold;
  text-transform: uppercase;
  &[disabled] {
    opacity: 0.5;
  }
`

const ErrorLabel = styled.span`
  margin: 20px 0 0;
  color: rgb(255,0,0);
`

const DonateBlock = props => {
  const {
    iban,
    paypal,
    petjeaf,
    showMollie,
  } = props

  const [paymentValue, setPaymentValue] = useState(10.00)
  const [isDonating, setDonating] = useState(false)
  const [error, setError] = useState('')

  const submitPayment = async (e) => {
    e.preventDefault()

    if (isDonating) {
      return
    }

    const host = window.location.hostname == 'localhost' ? 'http://localhost:3000' : ''
    const val = paymentValue

    if (isNaN(parseFloat(val))) {
      return setError('Geen geldig bedrag')
    }
    setDonating(true)
    try {
      const stripe = await stripePromise;
      const result = await fetch(`${host}/api/stripe/create_payment?amount=${val}`);
      if (result.status !== 200) {
        throw 'Something went wrong creating a Stripe payment:' + result.status
      } else {
        const { session } = await result.json()
        Cookies.set('paymentId', session, { expires: 7 })
        await stripe.redirectToCheckout({
          sessionId: session,
        });
      }
    } catch(err) {
      console.error(err)
      setError('Er is iets fout gegaan, probeer het opnieuw')
    }

    setDonating(false)
  }

  return (
    <Donate>
      <Heading>Support Jensen</Heading>
      <DonateWrapper>
        {iban && (
          <DonateTxt>
            <Icon>
              <Label>Doneer direct</Label>
              { iban } <br />
              RJP Media BV
            </Icon>
          </DonateTxt>
        )}
        {petjeaf && (
          <DonateBtn to={ petjeaf }>
            <Icon hover={ 0 }>
              <PetjeafIcon />
            </Icon>
            <Text>Petje.af</Text>
          </DonateBtn>
        )}
        {paypal && (
          <DonateBtn to={ paypal }>
            <Icon hover={ 1 }>
              <PayPalIcon />
            </Icon>
            <Text>Paypal</Text>
          </DonateBtn>
        )}
      </DonateWrapper>
      { showMollie && (
        <PaymentWrapper onSubmit={submitPayment}>
          <Label>Vul je bedrag in en doneer</Label>
          <InputWrapper>
            <PaymentInput type="number" value={paymentValue} onChange={(e) => { setPaymentValue(e.target.value) }} />
            <PaymentSubmit disabled={isDonating}>Doneer</PaymentSubmit>
          </InputWrapper>
          {error.length > 0 && (
            <ErrorLabel>{error}</ErrorLabel>
          )}
        </PaymentWrapper>
      )}
    </Donate>
  )
}

export default DonateBlock
