import { React, styled, mq } from 'x'
import { colors, textStyles } from 'styles'
import { useEffect, useState, useRef } from 'hooks'

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: 0.2s opacity ease-in;
  z-index: 10;

  ${mq.below('sm')} {
    background: rgb(0, 0, 0);
    height: 100%;
  }
`

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 440px;
  height: 550px;
  transform: translate(-50%, -50%);
  background-image: url('https://www.datocms-assets.com/15593/1693906114-los.png?w=440');
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: 0.2s opacity;
  z-index: 5;

  ${mq.below('sm')} {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: none;
    background-size: 150%;
    background-position: top;
  }
`

const Close = styled.button`
  ${textStyles.headingStyle}
  position: absolute;
  top: 6px;
  right: 10px;
  width: auto;
  height: 30px;
  border: 0;
  padding: 0;
  background-color: transparent;
  color: ${colors.white};
  font-size: 30px;
  line-height: 100%;
  cursor: pointer;
`

const Header = styled('div')`
  height: 40px;
  background-color: #e40606;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${textStyles.headingStyle}
  color: ${colors.white};
  font-size: 35px;
  line-height: 35px;
`

const Text = styled.span`
  ${textStyles.headingStyle}
  color: ${colors.yellow};
  font-size: 36px;

  text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000,
    1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;

  ${mq.below('sm')} {
    font-size: 32px;
  }
`
const Form = styled.form`
  margin: 10px 0;
`

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 3px 5px;
  font-size: 20px;
  text-align: center;

  &::placeholder {
    color: #000;
    font-family: 'oswald';
  }
`

const Body = styled.div`
  margin: 0 20px;
  height: 89%;
  display: flex;
  flex-direction: column;
  justify-content: end;
`

const Submit = styled.button`
  width: calc(100% - 40px);
  margin-top: 10px;
  padding: 4px 0 5px;
  border: 1px solid #000;
  border-radius: 8px;
  background: hsla(0, 100%, 17%, 1);
  box-sizing: border-box;

  background: hsla(0, 100%, 9%, 1);

  ${(p) =>
    p.disabled === false
      ? `  background: linear-gradient(90deg, hsla(0, 100%, 9%, 1) 0%,hsla(0, 95%, 46%, 1) 10%,hsla(0, 95%, 46%, 1) 89%,hsla(0, 100%, 83%, 1) 100%);
  background: -moz-linear-gradient(90deg,hsla(0, 100%, 9%, 1) 0%,hsla(0, 95%, 46%, 1) 10%,hsla(0, 95%, 46%, 1) 89%,hsla(0, 100%, 83%, 1) 100%);
  background: -webkit-linear-gradient(90deg,hsla(0, 100%, 9%, 1) 0%,hsla(0, 95%, 46%, 1) 10%,hsla(0, 95%, 46%, 1) 89%,hsla(0, 100%, 83%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#2C0000", endColorstr="#E40606", GradientType=1 );`
      : `  background: linear-gradient(90deg,hsla(0, 100%, 9%, 1) 0%,hsla(0, 95%, 35%, 1) 10%,hsla(0, 95%, 35%, 1) 89%,hsla(0, 100%, 83%, 1) 100%);
  background: -moz-linear-gradient(90deg,hsla(0, 100%, 9%, 1) 0%,hsla(0, 95%, 35%, 1) 10%,hsla(0, 95%, 35%, 1) 89%,hsla(0, 100%, 83%, 1) 100%);
  background: -webkit-linear-gradient(90deg,hsla(0, 100%, 9%, 1) 0%,hsla(0, 95%, 35%, 1) 10%,hsla(0, 95%, 35%, 1) 89%,hsla(0, 100%, 83%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#2C0000", endColorstr="#B30000", GradientType=1 );`}

  ${textStyles.headingStyle}
  color: ${colors.white};
  font-size: 28px;
  line-height: 100%;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const ErrorMsg = styled.span`
  display: block;
  color: red;
  font-size: 16px;
  margin: 5px 0 0;
`

const MailchimpPopup = (props) => {
  const { visible, hidePopup } = props
  const [show, setShow] = useState(false)
  const [unmounting, setUnmounting] = useState(false)

  const [email, setEmail] = useState('')
  const [emailInvalid, setEmailInvalid] = useState(false)

  const formEl = useRef(null)
  const wrapperEl = useRef(null)

  useEffect(() => {
    if (visible && !show && !unmounting) {
      setTimeout(() => {
        setShow(true)
        toggleShow()
      }, 500)
    }
  })

  const toggleShow = (disable = true) => {
    if (typeof window !== 'undefined' && window.document) {
      const html = window.document.getElementsByTagName('html')[0]
      const position = disable ? 'fixed' : 'static'
      const overflow = disable ? 'hidden' : 'visible'

      html.style.position = position
      html.style.overflow = overflow
    }

    setShow(disable)
  }

  const hide = (formSubmitted) => {
    toggleShow(false)
    setUnmounting(true)

    setTimeout(() => {
      hidePopup(formSubmitted)
    }, 500)
  }

  const backdropHide = (e) => {
    if (e.target === wrapperEl.current) {
      hide(false)
    }
  }

  useEffect(() => {
    if (!formEl.current) {
      return
    }

    formEl.current.onsubmit = () => {
      hide(true)
    }
  }, [formEl])

  const onEmailChange = (e) => {
    const {
      target: { value },
    } = e
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isEmailValid = regex.test(String(value).toLocaleLowerCase())

    setEmail(value)

    if (!isEmailValid || value.length <= 5) {
      setEmailInvalid(true)
    } else if (emailInvalid) {
      setEmailInvalid(false)
    }
  }

  return !visible ? null : (
    <Wrapper show={show} onClick={backdropHide} ref={wrapperEl}>
      <Popup show={show}>
        <Header>
          MELD JE NU AAN!
          <Close
            onClick={() => {
              hide(false)
            }}
          >
            X
          </Close>
        </Header>
        <Body>
          <Text>
            EXCLUSIEVE SHOWS
            <br /> PERSOONLIJKE BERICHTEN
            <br /> UPDATES BIJ IEDERE SHOW
          </Text>
          <Form
            action="https://jensen.us2.list-manage.com/subscribe/post"
            method="POST"
            target="_blank"
            ref={formEl}
          >
            <input type="hidden" name="u" value="9b8b11cc215b8bcb7490cc382" />
            <input type="hidden" name="id" value="3487d2f5d7" />
            <Input
              value={email}
              name="MERGE0"
              placeholder="Vul hier je e-mail in!"
              placeholderTextColor="green"
              onChange={onEmailChange}
            />
            <input type="hidden" value="" name="MERGE1" />
            {emailInvalid && (
              <ErrorMsg>Voer een geldig e-mail adres in</ErrorMsg>
            )}
            <Submit disabled={emailInvalid || email.length == 0}>
              Abonneer!
            </Submit>
          </Form>
        </Body>
      </Popup>
    </Wrapper>
  )
}

export default MailchimpPopup
