const colors = {
  primaryBlue: '#002d60',
  secundaryBlue: '#4db2ec',
  gradientBlue: '#003D88',
  textColor: '#111111',
  borderColor: '#EBEBEB',
  lightGrey: '#c1c1c1',
  red: '#dd3333',
  white: '#ffffff',
  black: '#000000',
  yellow: '#ffd83b',
}

export default colors
